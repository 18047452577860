<template>
  <div class="my-orders-table">
    <el-table
      :data="tableData"
      border
      height="700"
      style="width:100%"
      tooltip-effect="light"
      v-loading="tableDataLoading"
    >
      <el-table-column
        :formatter="createdFormatter"
        label="创建时间"
        prop="created"
        width="200"
      ></el-table-column>
      <el-table-column
        label="职位名称"
        prop="name"
        width="200"
      >
        <template slot-scope="{row, $index}">
          <span
            @click.prevent="handleJumpDetail(row, 'job', $index)"
            class="job-detail-link"
          >{{row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="职位类别"
        prop="jobType"
        width="200"
      >
        <template #header>
          <select-popover
            :enableCount="false"
            :enableSearch="true"
            :maxSelect="5"
            :selectItem="[]"
            @selectChange="selectDataChange"
            label="jobType"
            title="职位类别"
            type="SearchOnly"
          ></select-popover>
        </template>
        <template slot-scope="{row}">{{row.jobCategoryName}}</template>
      </el-table-column>
      <el-table-column
        label="客户公司"
        prop="customerName"
        width="280"
      >
        <template #header>
          <select-popover
            :enableCount="false"
            :enableSearch="true"
            :maxSelect="5"
            :selectItem="[]"
            @selectChange="selectDataChange"
            label="customerName"
            title="客户公司"
            type="SearchOnly"
          ></select-popover>
        </template>
      </el-table-column>
      <el-table-column
        :formatter="approvedStatusFormatter"
        label="审批状态"
        prop="approvedStatus"
        width="280"
      >
        <template #header>
          <select-popover
            :enableCount="false"
            :enableSearch="true"
            :maxSelect="5"
            :selectItem="headerFilter.approvedStatusItems"
            @selectChange="selectDataChange"
            label="approvedStatus"
            title="审批状态"
          ></select-popover>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="280"
      >
        <template slot-scope="{row}">
          <el-button
            @click="reApply(row)"
            type="text"
            v-if="row.approvedStatus == 4"
          >重新申请</el-button>
        </template>
      </el-table-column>

      <template slot="empty">
        <div>
          <span class="empty-img"></span>
          <p class="empty-data">暂无数据</p>
        </div>
      </template>
    </el-table>

    <table-page-tab
      :filterIndex="3"
      :pageTabs="pageTabJson"
    ></table-page-tab>

    <!-- footer -->
    <div class="work-table-footer">
      <div class="footer-left"></div>
      <el-pagination
        :current-page="page.current"
        :page-size="page.size"
        :page-sizes="[20, 30, 50]"
        :total="page.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        class="el-pagination-workTable"
        layout="total, sizes, prev, pager, next, slot"
      >
        <span class="pagination-text">
          <span>
            前往
            <el-input
              @keyup.enter.native="handlePagerJump"
              v-model="pagerJump"
            ></el-input>页
          </span>
          <span @click="handlePagerJump">跳转</span>
        </span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import JobService from '#/hr/js/service/jobService.js';
import { data as dataUrl } from '#/js/config/api.json';

import TablePageTab from '#/component/workTable/table-page-tab.vue';
import SelectPopover from '#/component/common/select-popover.vue';
import JobCategoryService from '#/js/service/jobCategoryService.js';


export default {
  name: 'myA2aAuditTable',
  componentName: "myA2aAuditTable",
  components: {
    TablePageTab,
    SelectPopover,
  },
  props: {
    dateRange: {
      type: Array,
    },
  },
  data () {
    return {
      tableDataLoading: false,
      tableData: [],

      pageTabJson: [{
        enTitle: "Orders",
        cnTitle: "我的订单",
        path: "/myOrders/grabbedJob",
        title: "我抢单的职位",
        tabIndex: 1,
        aliveComponent: 'MyOrdersGrabbedJob'
      }, {
        enTitle: "Orders",
        cnTitle: "我的订单",
        path: "/myOrders/createdJob",
        title: "我创建的职位",
        tabIndex: 0,
        aliveComponent: 'MyOrdersCreatedJob'
      }, {
        enTitle: "Orders",
        cnTitle: "我的订单",
        path: "/myOrders/sharedJob",
        title: "我分享的职位",
        tabIndex: 2,
        aliveComponent: 'MyOrdersSharedJob'
      }, {
        enTitle: "Orders",
        cnTitle: "我的订单",
        path: "/myOrders/a2aAuditJob",
        title: "A2A职位审批",
        tabIndex: 3,
        aliveComponent: 'MyOrdersA2aAuditJob'
      }],

      headerFilter: {
        jobTypeItems: [
          // {value: 0, label: '未指定', isSelect: false, count: 0,},
          // {value: 1, label: '市场职位', isSelect: false, count: 0,},
          // {value: 2, label: '公司内部职位', isSelect: false, count: 0,},

          // {value: 3, label: 'HR直招职位', isSelect: false, count: 0,},
          // {value: 4, label: 'A2A职位', isSelect: false, count: 0,},
          // {value: 5, label: '代运营职位', isSelect: false, count: 0,},
        ],
        approvedStatusItems: [
          // {value: 0, label: '无需审批', isSelect: false, count: 0,},
          // {value: 1, label: '自动审批通过', isSelect: false, count: 0,},
          { value: 2, label: '审批中', isSelect: false, count: 0, },
          // {value: 3, label: '审批通过', isSelect: false, count: 0,},
          { value: 4, label: '审批不通过', isSelect: false, count: 0, },
        ],
      },
      page: {
        current: 0,
        size: 20,
        total: 0,
      },
      pagerJump: 0,

      jobTypeArray: [],
      customerName: '',
      approvedStatusArray: [],

      jobCategories: [],
      jobCategoriesArray: [],
      jobCategoryName: '',
      // categoryMap: [],
    };
  },
  computed: {

  },
  created () {
    this.getJobList();
    this.getJobCategories();
  },
  mounted () {
  },
  activated () {
    if(this.$route.query.refresh == 'true') {
      this.getJobList();
    }
  },
  methods: {
    handleSizeChange (val) {
      this.page.size = val;
      this.page.current = 0;
      this.getJobList();
    },
    handleCurrentChange (val) {
      this.page.current = val;
      this.getJobList();
    },
    handlePagerJump () {
      let currentPager = Number(this.pagerJump),
        currentPageTotal = Math.ceil(this.page.total / this.page.size);
      if (currentPager > 0 && currentPager <= currentPageTotal) {
        this.handleCurrentChange(currentPager);
      }
    },
    // 获取职位类别
    getJobCategories () {
      _request({
        url: dataUrl.job_category,
        method: 'GET',
        apiType: 'oldApi',
      }).then(res => {
        this.headerFilter.jobTypeItems = res.subJobCategories.map(item => ({
          isSelect: false,
          count: 0,
          value: item.id,
          label: item.name,
        }));
        this.headerFilter.jobTypeItems.shift();
        this.jobCategories = res.subJobCategories;
        this.jobCategoriesArray = this.treeToArr(this.jobCategories);
      });
    },
    getJobList () {
      const params = {
        keyword: '',
        firmId: '',
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        minYearOfExperience: '',
        minDegree: '',
        jobLocation: '',
        jobName: '',
        ownerIds: [],
        start: this.page.current * this.page.size,
        take: this.page.size,
        jobStatus: [],
        customerName: this.customerName,
        approvedStatus: this.approvedStatusArray,

        // jobCategoryIds: this.jobTypeArray,
        jobCategoryName: this.jobCategoryName,
      };

      this.tableDataLoading = true;
      JobService
        .getApplyJobList(params)
        .then(res => {
          this.page.total = res.total;
          this.tableData = res.list || [];
          this.$emit('table-res', res);
        })
        .finally(() => {
          this.tableDataLoading = false;
        });
    },

    formatJobType (categoriesId) {
      // const statusTypeMap = [
      //     '未指定',
      //     '市场职位',
      //     '公司内部职位',
      //     'HR直招职位',
      //     'A2A职位',
      //     '代运营职位',
      // ];
      // return statusTypeMap[+type]
      return this.jobCategoriesArray.find(item => item.id == categoriesId)?.name || '--';
    },
    treeToArr (data) {
      const result = [];
      data.forEach(item => {
        const loop = data => {
          result.push({
            ...data
          });
          let child = data.subJobCategories;
          if (child) {
            for (let i = 0; i < child.length; i++) {
              loop(child[i]);
            }
          }
        };
        loop(item);
      });
      return result;
    },

    approvedStatusFormatter (row, column, cellValue, index) {
      const approvedStatusArray = [
        '无需审批',
        '自动审批通过',
        '审批中',
        '审批通过',
        '审批不通过',
      ];
      return approvedStatusArray[+cellValue];
    },

    createdFormatter (row, column, cellValue) {
      return moment(cellValue).format('YYYY-MM-DD');
    },
    handleJumpDetail (item, type, index) {
      if (type == 'job') {
        window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}?type=${item.type}&applicationId=${item.applicationId}&approvedStatus=${item.approvedStatus}`, '_blank');
      }
    },

    // 重新申请
    reApply (row) {
      // applicationId
      if(row.hasOrder) {
        location.href = `/Headhunting/MyCompany.html#/Job/${row.jobId}?type=${row.type}&applicationId=${row.applicationId}&approvedStatus=${row.approvedStatus}&editJobDetail=1`;
      } else {
        window.open(`/Headhunting/MyCompany.html#/jobCreate/single?applicationId=${row.applicationId}&jobId=${row.jobId}`, '_blank');
      }
    },

    selectDataChange (label, result) {

      if (result.length > 0) {
        if (label == 'customerName') {
          this.customerName = result[0].label || '';
        } else if (label == 'jobType') {
          this.jobCategoryName = result[0].label || '';
        } else {
          this.$data[label + 'Array'] = result.map(item => item.value);
        }
        this.$emit('handleAddLabel', label, result, true);
      } else {
        if (label == 'customerName') {
          this.customerName = '';
        } else if (label == 'jobType') {
          this.jobCategoryName = '';
        } else {
          this.$data[label + 'Array'] = [];
        }
        this.$emit("handleCleanLabel", label);
      }

      this.getJobList();
    },
    
  },
}
</script>
<style lang="scss" scoped>
@import '#/css/scss/variables.scss';

.color-orange {
  color: $orange;
}
.color-warn {
  color: #ff6564;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-pointer {
  cursor: pointer;
}
.my-orders-table /deep/ {
  height: 60.6% !important;
  padding: 0 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  letter-spacing: 0px;
  background: url('~@src/assets/images/home/home_bg.png') bottom center
    no-repeat;
  .el-table {
    overflow-y: auto;
    color: #666;
    z-index: 0;
    .el-table__body-wrapper,
    .el-table__fixed-body-wrapper {
      padding-bottom: 1px;
    }
    .el-loading-mask {
      z-index: 900;
    }
    .el-table__fixed::before {
      display: none;
    }
    th {
      padding: 0 0 0 13px;
      height: 42px;
      line-height: 42px;
      background-color: #e8e8e8;
      border-color: #ccc;
      color: #666;
      // &:nth-of-type(2) {
      //     padding-left: 2px;
      //     .el-icon--right {
      //         margin-left: 0;
      //     }
      // }
      &.recommend-cell {
        .icon-question {
          margin-left: 5px;
        }
      }
      &.gutter {
        display: none;
      }
      &.is-center {
        padding: 0;
      }
      .cell {
        padding: 0;
        .el-checkbox {
          display: none;
        }
      }
      .title-slot {
        padding: 0 !important;
      }
      div {
        display: block;
      }
    }
    td {
      padding: 0;
      height: 40px;
      line-height: 40px;
      .cell {
        white-space: nowrap;
        padding-left: 13px;
        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #fff;
          border-color: $primary;
          &::after {
            border-color: $primary;
          }
        }
        &.el-tooltip {
          div {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .table-body-icon-mr {
          margin-right: 4px;
        }
        .expected-rank,
        .activity-tag {
          height: 16px;
          margin-left: 6px;
          padding: 0 3px;
          border-radius: 4px;
          background: $primary;
          font-size: 12px;
          line-height: 16px;
          color: #fff;
        }
        .expected-rank {
          max-width: 68px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .activity-tag {
          background: #39f;
        }
        .time-tooltip-show {
          &:hover {
            color: $primary;
          }
        }
        .dispose-wrapper {
          .dispose-btn {
            display: none;
            color: $primary;
            margin-left: 10px;
            cursor: pointer;
          }
          &:hover {
            .dispose-btn {
              display: inline-block;
            }
          }
        }
      }
      &.is-center {
        .cell {
          padding: 0 3px;
        }
      }
      // &:nth-of-type(2) .cell{
      //     padding-left: 2px;
      // }
    }
    .el-table__row {
      .el-checkbox__inner {
        border-color: #999;
      }
    }
    .name-cell {
      position: relative;
      .cell {
        display: flex;
        align-items: center;
        .tip-margin {
          margin-left: 5px;
          .el-loading-spinner {
            margin-top: -12px;
            .circular {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
      .name-wrapper {
        width: auto;
        max-width: 234px;
        display: inline-flex;
        align-items: center;
        .job-detail-link {
          min-width: 20px;
          max-width: 128px;
        }
        .name-wrapper-icon {
          width: 17px;
          height: 17px;
        }
      }
      .new-job-tag {
        width: 26px;
        height: 16px;
        position: absolute;
        top: -1px;
        left: -1px;
      }
    }
    .job-detail-link,
    .customer-detail-link {
      color: #666;
      float: left;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
    .customer-detail-link {
      max-width: 136px;
      &.no-link {
        cursor: default;
        &:hover {
          color: #666;
        }
      }
    }
    .el-table__empty-block {
      min-height: 250px;
      .empty-data {
        display: block;
      }
    }
    .el-table-column--selection {
      border-right: none;
    }
    .el-table__empty-block {
      .el-table__empty-text {
        line-height: inherit;
      }
      .empty-img {
        display: inline-block;
        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
        width: 240px;
        height: 228px;
      }
      .empty-data {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>

