export default {
    methods: {
        tableScrollTo() {
            let table = this.$store.state.workbench.tabItems.find((tabItem) => {
                return tabItem.path === this.$route.path;
            });
            if(table.lastScrollTop !== undefined) {
                setTimeout(() => {
                    document.querySelector('.el-table__body-wrapper').scrollTo({ 
                        top: table.lastScrollTop, 
                        behavior: "smooth" 
                    });
                }, 50);
            }
        }
    }
}