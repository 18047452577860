<template>
    <MyA2aAduitWrapper />
</template>

<script>
import MyA2aAduitWrapper from './layout/myA2aAduitWrapper.vue'
import tableScrollTo from '#/js/mixins/tableScrollTo.js';
export default {
    name: "MyOrdersA2aAuditJob",
    components: {
        MyA2aAduitWrapper
    },
    data(){
        return{
            
        }
    },
    mixins: [tableScrollTo],
    activated() {
        this.tableScrollTo();
    },
}
</script>
<style lang="scss" scope>

</style>