var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-orders-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            height: "700",
            "tooltip-effect": "light",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              formatter: _vm.createdFormatter,
              label: "创建时间",
              prop: "created",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "职位名称", prop: "name", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row, $index }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "job-detail-link",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleJumpDetail(row, "job", $index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.name))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "职位类别", prop: "jobType", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("select-popover", {
                      attrs: {
                        enableCount: false,
                        enableSearch: true,
                        maxSelect: 5,
                        selectItem: [],
                        label: "jobType",
                        title: "职位类别",
                        type: "SearchOnly",
                      },
                      on: { selectChange: _vm.selectDataChange },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(_vm._s(row.jobCategoryName))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "客户公司", prop: "customerName", width: "280" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("select-popover", {
                      attrs: {
                        enableCount: false,
                        enableSearch: true,
                        maxSelect: 5,
                        selectItem: [],
                        label: "customerName",
                        title: "客户公司",
                        type: "SearchOnly",
                      },
                      on: { selectChange: _vm.selectDataChange },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.approvedStatusFormatter,
              label: "审批状态",
              prop: "approvedStatus",
              width: "280",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("select-popover", {
                      attrs: {
                        enableCount: false,
                        enableSearch: true,
                        maxSelect: 5,
                        selectItem: _vm.headerFilter.approvedStatusItems,
                        label: "approvedStatus",
                        title: "审批状态",
                      },
                      on: { selectChange: _vm.selectDataChange },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "280" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.approvedStatus == 4
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.reApply(row)
                              },
                            },
                          },
                          [_vm._v("重新申请")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("div", [
              _c("span", { staticClass: "empty-img" }),
              _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
            ]),
          ]),
        ],
        2
      ),
      _c("table-page-tab", {
        attrs: { filterIndex: 3, pageTabs: _vm.pageTabJson },
      }),
      _c(
        "div",
        { staticClass: "work-table-footer" },
        [
          _c("div", { staticClass: "footer-left" }),
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                "current-page": _vm.page.current,
                "page-size": _vm.page.size,
                "page-sizes": [20, 30, 50],
                total: _vm.page.total,
                layout: "total, sizes, prev, pager, next, slot",
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("\n          前往\n          "),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页\n        "),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }