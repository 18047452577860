<template>
  <div class="work-table-container">
    <div class="my-orders-wrapper">
      <div class="top-wrap">
        <date-range-picker
          @date-picked="handleDatePicked"
          size="sm"
        />
      </div>

      <selectLabel
        :count="total"
        :labelList="labelList"
        :orderLabelShowList="orderLabelShowList"
        @handleCleanLabel="handleSelectCleanLabel"
        ref="searchLabel"
      ></selectLabel>

      <MyA2aAuditTable
        :date-range="dateRange"
        @handleAddLabel="handleAddLabel"
        @handleCleanLabel="handleCleanLabel"
        @table-res="handleTableData"
        ref="myA2aAuditTable"
      />
    </div>
  </div>
</template>

<script>
import SelectWrapper from './selectWrapper.vue';
import SelectLabel from './selectLabel.vue';
import MyA2aAuditTable from './myA2aAuditTable.vue';
import emitter from '#/js/mixins/emitter.js';
import DateRangePicker from '#/component/common/date-range-picker.vue';

import moment from 'moment';
const TODAY = moment().format('YYYY-MM-DD');
const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");


export default {
  name: "myA2aAduitWrapper",
  componentName: "myA2aAduitWrapper",
  mixins: [emitter],
  components: {
    SelectWrapper,
    SelectLabel,
    MyA2aAuditTable,
    DateRangePicker,
  },
  props: {
    tableType: {
      type: Number,
      default () {
        return 2;
      }
    },
  },
  data () {
    return {

      labelList: {
        customerName: [],
        approvedStatus: [],
      },

      orderLabelMap: new Set(),
      orderLabelShowList: [],

      total: 0,
      tableData: [],
      headerFilter: {},

      dateRange: [lastMonth, TODAY],
    };
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  activated () {
  },
  methods: {

    handleAddLabel (label, result) {
      const idx = this.orderLabelShowList.findIndex(item => item == label);
      if (idx < 0) {
        this.orderLabelShowList.push(label);
      }
      this.labelList[label] = result.map(item => ({
        ...item,
        text: item.label,
      }));
    },

    handleCleanLabel (label) {
      const _idx = this.orderLabelShowList.findIndex(item => item == label);
      if (_idx >= 0) {
        this.orderLabelShowList.splice(_idx, 1);
      }
      this.orderLabelShowList.splice();
      if (this.labelList[label].length <= 0) {
        return false;
      }
      this.labelList[label] = [];
    },

    handleSelectCleanLabel (label) {
      const _idx = this.orderLabelShowList.findIndex(item => item == label);
      if (_idx >= 0) {
        this.orderLabelShowList.splice(_idx, 1);
      }
      this.orderLabelShowList.splice();
      if (this.labelList[label].length <= 0) {
        return false;
      }
      this.labelList[label] = [];


      // 重置刷新数据
      if (label == 'customerName') {
        this.$refs.myA2aAuditTable.customerName = '';
      }
      if (label == 'approvedStatus') {
        this.$refs.myA2aAuditTable.approvedStatusArray = [];
        this.$refs.myA2aAuditTable.headerFilter.approvedStatusItems.forEach(item => {
          item.isSelect = false;
        });
      }
      if (label == 'jobType') {
        this.$refs.myA2aAuditTable.jobCategoryName = '';
      }
      this.$refs.myA2aAuditTable.getJobList();
    },

    handleDatePicked (dates) {
      if (dates.length == 2) {
        this.dateRange = dates;
        this.$nextTick(() => {
          this.$refs.myA2aAuditTable.getJobList();
        });
      }
    },

    handleTableData (res) {
      this.total = res.total;
    },
  },
}
</script>

<style lang="scss" scope>
.work-table-container {
  height: 100%;
  overflow-y: auto;
  .my-orders-wrapper {
    position: relative;
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .top-wrap {
      margin: 10px 20px 0 20px;
      padding: 10px 20px;
      background-color: #fff;
      border-radius: 4px;
    }
  }
}
</style>
