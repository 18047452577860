var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "work-table-container" }, [
    _c(
      "div",
      { staticClass: "my-orders-wrapper" },
      [
        _c(
          "div",
          { staticClass: "top-wrap" },
          [
            _c("date-range-picker", {
              attrs: { size: "sm" },
              on: { "date-picked": _vm.handleDatePicked },
            }),
          ],
          1
        ),
        _c("selectLabel", {
          ref: "searchLabel",
          attrs: {
            count: _vm.total,
            labelList: _vm.labelList,
            orderLabelShowList: _vm.orderLabelShowList,
          },
          on: { handleCleanLabel: _vm.handleSelectCleanLabel },
        }),
        _c("MyA2aAuditTable", {
          ref: "myA2aAuditTable",
          attrs: { "date-range": _vm.dateRange },
          on: {
            handleAddLabel: _vm.handleAddLabel,
            handleCleanLabel: _vm.handleCleanLabel,
            "table-res": _vm.handleTableData,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }